*,
*::after,
*::before {
  box-sizing: border-box;
  transition: ease 0.3s;
}

:root {
  --primary-color-white: #fff;
  --primary-color-blue: #5b7fff;
  --primary-color-danger: #ff5630;
  --primary-color-dark: #4e5d78;
  --table-header-bg: #e5eaff;
  --table-row-border: 1px solid #dae5ff4f;
  --green: #6fcf97;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-color-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button,
input,
textarea {
  outline: none !important;
  font-family: "Barlow", sans-serif;
  font-size: 14px;
}
button {
  cursor: pointer;
}
[disabled="true"],
button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
/* width and height */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5b7fff;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5b7fff;
}
