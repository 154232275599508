/* Header Item styles */
.tableHeaderItem:nth-child(1),
.tableBodyClass:nth-child(1) {
  width: 4%;
  text-transform: capitalize;
}
.tableHeaderItem:nth-child(2),
.tableBodyClass:nth-child(2) {
  width: 18%;
  text-transform: capitalize;
  padding-right: 1rem;
}
.tableHeaderItem:nth-child(3),
.tableBodyClass:nth-child(3) {
  width: 24%;
  text-transform: capitalize;
  padding-right: 1rem;
}
.tableHeaderItem:nth-child(4),
.tableBodyClass:nth-child(4) {
  width: 23%;
  text-transform: capitalize;
  padding-right: 1rem;
}
.tableHeaderItem:nth-child(5),
.tableBodyClass:nth-child(5) {
  width: 13%;
  text-transform: capitalize;
}
.tableHeaderItem:nth-child(6),
.tableBodyClass:nth-child(6) {
  width: 10%;
  text-transform: capitalize;
}
.tableHeaderItem:nth-child(7),
.tableBodyClass:nth-child(7) {
  width: 8%;
}
.tableWrapperClass {
  overflow-x: hidden;
  width: 100%;
  overflow-x: auto;
}
.tableHeaderClass {
  min-width: 800px;
}

/* Statuses */
.wrapStatus {
  border: 1px solid #b0b7c3;
  font-weight: 600;
  text-transform: capitalize;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 13px;
  display: inline-block;
  padding-right: 18px;
  width: 94px;
}
.sent {
  color: #38cb89;
}
.review {
  color: #5b7fff;
}
.admission {
  color: #4b369d;
}
.submitted {
  color: #4e5d78;
}
.payable {
  color: #b0325e;
}
.rejected {
  color: #ff5630;
}
.accepted {
  color: #ffab00;
}

/* Status Filter Dropdown */
.filterWrap {
  padding: 8px;
  border-radius: 8px;
  position: relative;
  width: 80px;
  border: 1px solid #e5e5e5;
  padding: 6px 8px;
}
.filterList {
  position: absolute;
  top: 100%;
  background: #fff;
  left: 0;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 10%);
  border-radius: 6px;
}
.filterList div {
  padding: 6px 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.filterList div:last-of-type {
  border: none;
}
.filter {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.filter svg {
  transform: rotate(90deg);
  margin-left: auto;
}
.actionWrap {
  display: flex;
  justify-content: space-between;
}
.reloadBtn {
  color: #5b7fff;
  background: transparent;
  padding: 6px 1.5rem;
  border: none;
  font-weight: 500;
  text-decoration: underline;
}
.searchInputWrapper {
  width: 100%;
  margin-right: 1rem;
  max-width: 500px;
  position: relative;
  height: 36px;
}
.searchInputWrapper svg {
  position: absolute;
  width: 18px;
  left: 6px;
  top: 9px;
}
.searchInput {
  padding-left: 2rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}
.noresults {
  text-align: center;
  margin: 7rem 1rem;
  font-size: 2rem;
  font-weight: 500;
  color: #4e5d7880;
}

.filterWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.3rem;
  flex-wrap: wrap;
}

.dropdownWrap {
  width: 140px;
}

.createBtn {
  background: #5b7fff;
  color: #fff;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 6px 1rem;
  margin-top: -3rem;
  margin-bottom: 2.5rem;
}
.createBtn svg {
  margin-right: 10px;
}

@media screen and (max-width: 450px) {
  .filterWrapper {
    justify-content: space-between;
  }
  .filterWrapper > div {
    width: 48% !important;
  }
}
